import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: ${(props) => props.width || "100%"};
  flex-direction: ${(props) => props.flexDirection || "column"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "center"};
  margin: auto;
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
`;
