import styled from "styled-components";
import { Constants } from "../../config/constants";
import { IconButton } from "@mui/material";

const colors = Constants.theme.colors;

export const StyledSubmitButton = styled.button`
  width: 100%;
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  background: ${(props) => props.background || colors.primary};
  height: ${(props) => props.height || "50px"};
  border-radius: 1000px;
  color: ${colors.white};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background: ${(props) => props.background || colors.primary};
    opacity: 0.9;
    transition: 0.2s ease-out;
  }

  &:active {
    transition: 0.2s ease-out;
    outline: none;
    border: 2px solid #1279fc;
  }

  &:disabled {
    background: ${colors.lightGrey};
    color: ${colors.darkGrey};
    cursor: not-allowed;
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: ${colors.primary};
  margin-left: auto;
  margin-right: 0;
  padding: 0;

  &:hover {
    background: ${colors.lightGrey};
    border-radius: 1000px;
    transition: 0.2s ease-out;
  }
`;
