import { usePlatformContext } from "config/context";
import Redirect from "pages/Redirect";
import { Outlet } from "react-router";

export const RequireAuth = () => {
  const context = usePlatformContext();
  if (!context.loading && !context.signedIn) {
    <Redirect />;
  } else {
    return <Outlet />;
  }
};
