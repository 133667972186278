import { useCustomToast } from "hooks/toast";
import React, { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/api";
import { useUtils } from "hooks/utils";
import { dummy } from "./dummy";
import { useNavigate } from "react-router";

const Context = React.createContext();

export const ContextWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { showToast } = useCustomToast();
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(true);
  const [user, setUser] = useState(dummy.user);
  const api = useApi({ showToast });
  const utils = useUtils();

  useEffect(() => {
    if (loading) {
      api.resetToken().then((data) => {});
      api.getUser().then((data) => {
        if (data.status) {
          setSignedIn(data.status);
          setUser(data.data);
          setLoading(false);
        } else {
          navigate("/admin");
        }
      });
    }
  }, [loading, api, user, navigate]);

  const contextData = {
    showToast,
    api,
    loading,
    setLoading,
    utils,
    user,
    signedIn,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const usePlatformContext = () => {
  return useContext(Context);
};
