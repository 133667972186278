import axios from "axios";
import { useNavigate } from "react-router";

export const useApi = (props) => {
  const navigate = useNavigate();
  const authInstance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_BACKEND + "/auth",
    withCredentials: true,
    timeout: 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const adminInstance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_BACKEND + "/admin",
    withCredentials: true,
    timeout: 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // const instance = axios.create({
  //   baseURL: process.env.REACT_APP_BACKEND,
  //   withCredentials: true,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });

  const toastHandler = (data) => {
    if (!data.status) {
      props.showToast("error", data.message);
    } else {
      props.showToast("success", data.message);
    }
  };

  const apiCall = async (method) => {
    let data;
    try {
      const res = await method();
      data = res.data;
    } catch (err) {
      if (err.response.data.message === "Your session is not valid.") {
        authInstance
          .post("/token/reset")
          .then((data) => {})
          .catch((err) => {
            navigate("/login");
          });
        err.response.data.message =
          "Your session was not valid. Please try again.";
      }
      data = err.response.data;
    }

    return data;
  };

  const resetToken = async () => {
    const data = await apiCall(() => authInstance.post("/token/reset"));
    if (!data.status) {
      navigate("/login");
    }
    return data;
  };

  const getUser = async () => {
    const data = await apiCall(() => adminInstance.get("/me"));
    return data;
  };

  const logout = async () => {
    const data = await apiCall(() => adminInstance.get("/logout"));
    toastHandler(data);
    if (data.status) {
      navigate("/login");
    }
    return data;
  };

  return {
    resetToken,
    getUser,
    logout,
  };
};
