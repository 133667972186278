import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const LayoutFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

export const LayoutHeader = styled.div`
  display: flex;
  padding: 15px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 768px) {
    padding: 15px 0;
  }
`;

export const LayoutMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${(props) => (props.normal ? "start" : "center")};
  padding: 20px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
